import { Address } from "viem";
import { delegationWalletRegistryAbi } from "./abis/delegationWalletRegistryAbi";
import AbstractContract from "./AbstractContract";

export class DelegationWalletRegistryContract extends AbstractContract<
  typeof delegationWalletRegistryAbi
> {
  constructor(address: Address) {
    super({ address }, delegationWalletRegistryAbi);
  }

  async getOwnerWalletAddresses(externalAccount: Address) {
    return (await this.viemReadContract).read.getOwnerWalletAddresses([
      externalAccount,
    ]);
  }
}
