import { useEffect, useState } from "react";
import { Loan } from "../types/loan/Loan";
import LoanAuctioned from "../types/loan/LoanAuctioned";

export const useRedeemData = (item: Loan | LoanAuctioned) => {
  const [bidFine, setBidFine] = useState<bigint>();
  const [redeemAmount, setRedeemAmount] = useState<bigint>();

  useEffect(() => {
    if (item instanceof LoanAuctioned) {
      handleGetBidFine();
      handleGetRedeemAmount();
    }
  }, []);

  const handleGetBidFine = async () => {
    if (item instanceof LoanAuctioned) {
      setBidFine(await item.bidFine!);
    }
  };

  const handleGetRedeemAmount = async () => {
    if (item instanceof LoanAuctioned) {
      setRedeemAmount(await item.redeemAmount!);
    }
  };

  return { bidFine, redeemAmount };
};
