import { bulkTransferAbi } from "./abis/bulkTransferAbi";
import { Address } from "viem";
import { WalletType } from "./types";
import executeContractMethod from "../logic/helpers/executeContractMethod";
import { OptionsWriteMethod } from "@unlockdfinance/verislabs-web3";
import AbstractContract from "./AbstractContract";

export default class BulkTransferContract extends AbstractContract<
  typeof bulkTransferAbi
> {
  constructor(address: Address) {
    super({ address }, bulkTransferAbi);
  }

  batchTransferFrom(
    nfts: { contractAddress: Address; tokenId: bigint }[],
    to: Address,
    options?: OptionsWriteMethod,
    walletType: WalletType = WalletType.SMART
  ) {
    return executeContractMethod(
      this,
      "batchTransferFrom",
      [nfts, to],
      options,
      walletType
    );
  }

  batchPunkTransferFrom(
    nfts: { contractAddress: Address; tokenId: bigint }[],
    to: Address,
    options?: OptionsWriteMethod,
    walletType: WalletType = WalletType.SMART
  ) {
    return executeContractMethod(
      this,
      "batchPunkTransferFrom",
      [nfts, to],
      options,
      walletType
    );
  }
}
