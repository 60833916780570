import { Abi } from "viem";
import { WalletType } from "../../contracts/types";
import { isSmartWallet } from "../../contracts/helpers";
import handleSafeTransaction from "./handleSafeTransaction";
import { OptionsWriteMethod, Output } from "@unlockdfinance/verislabs-web3";
import { externalWalletModule } from "../../clients/verisModule";
import AbstractContract from "../../contracts/AbstractContract";

export default function executeContractMethod<TAbi extends Abi, T>(
  contract: AbstractContract<TAbi>,
  methodName: string,
  parameters: T[],
  options?: OptionsWriteMethod,
  walletType: WalletType = WalletType.SMART
): Promise<Output<void>> {
  return isSmartWallet(walletType)
    ? handleSafeTransaction<TAbi, void>(
        contract,
        methodName,
        parameters,
        undefined,
        options
      )
    : externalWalletModule.handleViemTransaction(
        async () =>
          // @ts-ignore
          (await contract.viemWriteContract).write[methodName](parameters),
        options
      );
}
