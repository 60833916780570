"use client";
import { useEffect, useMemo, useState } from "react";
import { handleErrors } from "../../errors";
import { useErrorAlert } from "./useErrorAlert";
import { useCustomWallet } from "../../components/providers";
import { Address } from "viem";
import { OptionsWriteMethod } from "@unlockdfinance/verislabs-web3";
import currenciesModule from "logic/CurrenciesModule";

export const useAllowanceV2 = (
  amount: bigint,
  spender: Address,
  erc20Address: Address | Promise<Address> = currenciesModule.defaultCurrency
    .address
) => {
  const [allowance, setAllowance] = useState<bigint | undefined>();
  const [error, setError] = useState<Error | null>(null);
  useErrorAlert(error);
  const { address } = useCustomWallet();

  useEffect(() => {
    if (address) {
      handleGetAllowance(address);

      return () => setAllowance(BigInt(0));
    }
  }, [address]);

  const handleGetAllowance = async (address: Address) => {
    try {
      const currency = currenciesModule.getErc20CurrencyByAddress(
        await erc20Address
      );

      if (!currency) throw new Error("Currency not found");

      const allowance = await currency.contract.allowance(address, spender);

      setAllowance(allowance);
    } catch (err) {
      setError(handleErrors(err));
    }
  };

  const amountToApprove = useMemo<bigint>(
    () =>
      allowance !== undefined && amount - allowance > BigInt(0)
        ? amount
        : BigInt(0),
    [allowance, amount]
  );

  const handleApprove = async (
    _amount?: bigint,
    options?: OptionsWriteMethod
  ) => {
    if (address) {
      const correctedAmount = _amount || amountToApprove;

      if (correctedAmount !== undefined && correctedAmount > BigInt(0)) {
        const currency = currenciesModule.getErc20CurrencyByAddress(
          await erc20Address
        );

        if (!currency) throw new Error("Currency not found");

        await currency.contract.approve(spender, correctedAmount, options);

        setAllowance((prevValue) => (prevValue || BigInt(0)) + correctedAmount);

        // setTimeout(() => handleGetAllowance(address), 10000);
      }
    }
  };

  const isApproved = useMemo<boolean>(
    () => allowance !== undefined && amountToApprove === BigInt(0),
    [allowance, amountToApprove]
  );

  return { allowance, handleApprove, isApproved };
};
