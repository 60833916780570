import { Address, VerisContract } from "@unlockdfinance/verislabs-web3";
import { Abi } from "viem";
import { verisModule } from "../clients/verisModule";

export default abstract class AbstractContract<
  TAbi extends Abi
> extends VerisContract<TAbi> {
  constructor(
    {
      address,
      moduleId,
    }: {
      address?: Address;
      moduleId?: string;
    },
    abi: TAbi
  ) {
    super(verisModule, { address, moduleId }, abi);
  }
}
