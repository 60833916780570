import { Address } from "viem";
import { customERC721Abi } from "./abis/customERC721";
import { WalletType } from "./types";
import executeContractMethod from "../logic/helpers/executeContractMethod";
import { OptionsWriteMethod, Output } from "@unlockdfinance/verislabs-web3";
import AbstractContract from "./AbstractContract";

export class ERC721Contract extends AbstractContract<typeof customERC721Abi> {
  genericImage?: string;
  uBoundAddress?: Address;

  constructor(address: Address, genericImage?: string) {
    super({ address }, customERC721Abi);

    this.genericImage = genericImage;
  }

  async balanceOf(address: Address): Promise<bigint> {
    return (await this.viemReadContract).read.balanceOf([address]);
  }

  async maxSupply(): Promise<bigint> {
    return (await this.viemReadContract).read.MAX_SUPPLY();
  }

  async totalSupply(): Promise<bigint> {
    return (await this.viemReadContract).read.totalSupply();
  }

  async isApprovedForAll(sender: Address, operator: Address): Promise<boolean> {
    return (await this.viemReadContract).read.isApprovedForAll([
      sender,
      operator,
    ]);
  }

  async tokenUri(tokenId: bigint) {
    return (await this.viemReadContract).read.tokenURI([tokenId]);
  }

  async transferFrom(
    from: Address,
    to: Address,
    tokenId: bigint,
    walletType: WalletType = WalletType.BASIC,
    options?: OptionsWriteMethod
  ): Promise<Output<void>> {
    return executeContractMethod(
      this,
      "transferFrom",
      [from, to, tokenId],
      options,
      walletType
    );
  }

  mint(
    address: Address,
    amount: number,
    options?: OptionsWriteMethod,
    walletType: WalletType = WalletType.BASIC
  ): Promise<Output<void>> {
    return executeContractMethod(
      this,
      "mint",
      [address, amount],
      options,
      walletType
    );
  }

  setApprovalForAll(
    address: Address,
    walletType: WalletType = WalletType.BASIC,
    options?: OptionsWriteMethod
  ): Promise<Output<void>> {
    return executeContractMethod(
      this,
      "setApprovalForAll",
      [address, true],
      options,
      walletType
    );

    // return externalWalletModule.handleViemTransaction(
    //   async () =>
    //     // @ts-ignore
    //     (await this.viemWriteContract).write.setApprovalForAll([address, true]),
    //   options
    // );
  }
}
