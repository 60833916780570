import { actionAbi } from "./abis/actionAbi";
import { Address } from "viem";
import { externalWalletModule } from "../clients/verisModule";
import {
  Hash,
  OptionsWriteMethod,
  Output,
} from "@unlockdfinance/verislabs-web3";
import AbstractContract from "./AbstractContract";

export type LoanDataFromContract = {
  loanId: `0x${string}`;
  totalAssets: bigint;
  state: number;
  underlyingAsset: `0x${string}`;
  owner: `0x${string}`;
};

export default class ActionContract extends AbstractContract<typeof actionAbi> {
  constructor(moduleId: string) {
    super({ moduleId }, actionAbi);
  }

  async getLoan(loanId: Hash): Promise<LoanDataFromContract> {
    return (await this.viemReadContract).read.getLoan([loanId]);
  }

  async getAmountToRepay(loanId: Hash) {
    return (await this.viemReadContract).read.getAmountToRepay([loanId]);
  }

  borrow(
    amount: bigint,
    nfts: { collection: Address; tokenId: bigint }[],
    data: any,
    signature: any,
    options?: OptionsWriteMethod
  ): Promise<Output<void>> {
    return externalWalletModule.handleViemTransaction(
      async () =>
        // @ts-ignore
        (await this.viemWriteContract).write.borrow([
          amount,
          nfts,
          data,
          signature,
        ]),
      options
    );
  }

  repay(
    amount: bigint,
    data: any,
    signature: any,
    options?: OptionsWriteMethod
  ): Promise<Output<void>> {
    return externalWalletModule.handleViemTransaction(
      async () =>
        // @ts-ignore
        (await this.viemWriteContract).write.repay([amount, data, signature]),
      options
    );
  }

  // async onRepay(loanId?: Address, onLogs?: (log: Log) => void) {
  //   return (await this.viemReadContract).watchEvent.Repay(
  //     { loanId },
  //     // @ts-ignore
  //     { onLogs }
  //   );
  // }
}
