import { Address } from "viem";
import { cryptopunksAbi } from "./abis/cryptopunksAbi";
import { WalletType } from "./types";
import executeContractMethod from "../logic/helpers/executeContractMethod";
import { OptionsWriteMethod, Output } from "@unlockdfinance/verislabs-web3";
import { externalWalletModule } from "../clients/verisModule";
import AbstractContract from "./AbstractContract";

export default class CryptopunksContract extends AbstractContract<
  typeof cryptopunksAbi
> {
  constructor(address: Address) {
    super({ address }, cryptopunksAbi);
  }

  async punkIndexToAddress(punkId: bigint): Promise<Address> {
    return (await this.viemReadContract).read.punkIndexToAddress([punkId]);
  }

  async punksOfferedForSale(punkIndex: bigint) {
    return (await this.viemReadContract).read.punksOfferedForSale([punkIndex]);
  }

  async allPunksAssigned(): Promise<boolean> {
    return (await this.viemReadContract).read.allPunksAssigned();
  }

  async nextPunkIndexToAssign(): Promise<bigint> {
    return (await this.viemReadContract).read.nextPunkIndexToAssign();
  }

  async punksRemainingToAssign(): Promise<bigint> {
    return (await this.viemReadContract).read.punksRemainingToAssign();
  }

  getPunk(
    punkIndex: bigint,
    options?: OptionsWriteMethod
  ): Promise<Output<void>> {
    return externalWalletModule.handleViemTransaction(
      // @ts-ignore
      async () => (await this.viemWriteContract).write.getPunk([punkIndex]),
      options
    );
  }

  transferPunk(
    to: Address,
    punkIndex: BigInt,
    options?: OptionsWriteMethod,
    walletType: WalletType = WalletType.SMART
  ): Promise<Output<void>> {
    return executeContractMethod(
      this,
      "transferPunk",
      [to, punkIndex],
      options,
      walletType
    );
  }

  offerPunkForSaleToAddress(
    punkIndex: bigint,
    minSalePrice: bigint = BigInt(0),
    to: Address,
    options?: OptionsWriteMethod,
    walletType: WalletType = WalletType.SMART
  ): Promise<Output<void>> {
    return executeContractMethod(
      this,
      "offerPunkForSaleToAddress",
      [punkIndex, minSalePrice, to],
      options,
      walletType
    );
  }
}
