import useAlertNotification from "components/hooks/useAlertNotification";
import { handleErrors } from "errors";
import { Flow } from "errors/handleErrors";
import { useEffect, useState } from "react";

export const useErrorAlertV2 = () => {
  const [error, setError] = useState<Error | null>(null);

  const [
    isAlertNotificationOpen,
    openAlertNotification,
    closeAlertNotification,
  ] = useAlertNotification();

  useEffect(() => {
    if (error) {
      if (isAlertNotificationOpen) {
        closeAlertNotification();
      }

      openAlertNotification("error", error.message, 5000);
    }
  }, [error]);

  const handleSetError = (error: Error | null | any, flow?: Flow) => {
    setError(handleErrors(error, flow));
  };

  return { handleSetError, error };
};
