import { useRouter, useSearchParams } from "next/navigation";
import { useEffect, useState } from "react";

export const useCustomParams = () => {
  const searchParams = useSearchParams();
  const router = useRouter();

  // TODO: implement this to replace useSearchParams from nextjs
  // const [searchParams, setSearchParams] = useState<URLSearchParams>();
  // useEffect(() => {
  //   window.onpopstate = () => {

  //   }
  // }, [])

  const getAllValuesFromParam = (param: string) => {
    const url = new URL(window.location.href);

    const params = new URLSearchParams(url.search);

    return params.getAll(param);
  };

  const getParamValue = (param: string) => {
    const url = new URL(window.location.href);

    const params = new URLSearchParams(url.search);

    return params.get(param);
  };

  const toggleParamValue = (param: string, value: string) => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);

    const values = params.getAll(param);

    params[values.includes(value) ? "delete" : "append"](param, value);

    router.push(`${url.pathname}?${params.toString()}`, {
      scroll: false,
    });
  };

  const removeAllValuesFromParam = (param: string) => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);

    params.delete(param);

    router.push(`${url.pathname}?${params.toString()}`, {
      scroll: false,
    });
  };

  const removeValuesFromParam = (param: string, values: string[]) => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);

    values.forEach((value) => {
      params.delete(param, value);
    });

    router.push(`${url.pathname}?${params.toString()}`, {
      scroll: false,
    });
  };

  const setOrReplaceParamValue = (
    param: string,
    value: string,
    cleanOtherParams: boolean = false
  ) => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(
      !cleanOtherParams ? url.search : undefined
    );

    params.set(param, value);

    router.push(`${url.pathname}?${params.toString()}`, {
      scroll: false,
    });
  };

  const toggleBooleanParam = (param: string) => {
    const url = new URL(window.location.href);

    const params = new URLSearchParams(url.search);

    if (params.get(param) === "true") {
      setOrReplaceParamValue(param, "false");
    } else {
      setOrReplaceParamValue(param, "true");
    }
  };

  return {
    getAllValuesFromParam,
    getParamValue,
    toggleParamValue,
    toggleBooleanParam,
    removeAllValuesFromParam,
    removeValuesFromParam,
    searchParams,
    setOrReplaceParamValue,
  };
};
