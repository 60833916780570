"use client";
import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useState,
  useEffect,
} from "react";
import { WalletClient, Address } from "viem";
import Safe from "@safe-global/protocol-kit";
import { Connector } from "@wagmi/core";
import unlockdWalletModule, {
  UnlockdWalletSubsCallback,
} from "../../logic/UnlockdWalletModule";
import { WalletSubsCallback } from "@unlockdfinance/verislabs-web3";
import { externalWalletModule } from "../../clients/verisModule";

type IContext = Partial<{
  address: Address | null;
  isConnected: boolean;
  smartAddress: Address | null | undefined;
  hasSmartAddress: boolean | undefined;
  client: WalletClient | null;
  isClientAvailable: boolean;
  safeClient: Safe | undefined;
  connector: Connector | null;
  chainId: number | null;
  onUnlockdWalletCreated: (safeAddress: Address) => void;
}>;

const WalletContext = createContext<IContext>({});

export const WalletProvider: FC<PropsWithChildren> = ({ children }) => {
  // const [error, setError] = useState<Error | null>(null);
  // useErrorAlert(error);
  const [client, setClient] = useState<WalletClient | null>(null);
  const [address, setAddress] = useState<Address | null | undefined>(undefined);
  const [chainId, setChainId] = useState<number | null>(null);
  const [connector, setConnector] = useState<Connector | null>(null);
  const [smartAddress, setSmartAddress] = useState<Address | null | undefined>(
    undefined
  );
  const [safeClient, setSafeClient] = useState<Safe>();

  useEffect(() => {
    const extWalletCallback: WalletSubsCallback = (
      _address,
      _chainId,
      _client,
      _connector
    ) => {
      setAddress(_address);
      setChainId(_chainId);
      setClient(_client);
      setConnector(_connector);
    };

    externalWalletModule.subscribe(extWalletCallback);

    const unlockdWalletCallback: UnlockdWalletSubsCallback = (
      unlockdAddress,
      safeClient
    ) => {
      setSmartAddress(unlockdAddress);
      setSafeClient(safeClient);
    };

    unlockdWalletModule.subscribe(unlockdWalletCallback);

    return () => {
      externalWalletModule.unsubscribe(extWalletCallback);
      unlockdWalletModule.unsubscribe(unlockdWalletCallback);
    };
  }, []);

  const handleUnlockdWalletCreated = (safeAddress: Address) => {
    setSmartAddress(safeAddress);
    unlockdWalletModule.unlockdAddress = safeAddress;
  };

  return (
    <WalletContext.Provider
      value={{
        client,
        address,
        isConnected: !!address,
        isClientAvailable: !!client,
        chainId: chainId,
        connector,
        smartAddress,
        hasSmartAddress:
          smartAddress === undefined
            ? undefined
            : smartAddress === null
            ? false
            : true,
        onUnlockdWalletCreated: handleUnlockdWalletCreated,
        safeClient,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export const useCustomWallet = () => {
  const walletObj = useContext(WalletContext);

  return walletObj;
};
