import { useCallback, useState } from "react";
import { validateAddress } from "utils";

export const useAddressSelectionLogic = () => {
  const [address, setAddress] = useState<string>();
  const [addressError, setAddressError] = useState<Error | null>(null);

  const validateInputAddress = (_address?: string): Error | null => {
    let error: Error | null = null;

    const addressToCheck = _address || address;

    try {
      validateAddress(addressToCheck);
    } catch (err) {
      error = err as Error;
    }

    setAddressError(error);

    return error;
  };

  const handleAddressChange = useCallback(
    (address: string) => {
      setAddress(address);

      if (addressError) {
        validateInputAddress(address);
      }
    },
    [addressError]
  );

  return {
    addressInputValue: address,
    addressError,
    handleAddressChange,
    validateInputAddress,
  };
};
