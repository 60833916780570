import { Address } from "viem";
import { MarketplaceFilter } from "./MarketplaceModule";
import { MarketItemType } from "../contracts/MarketContract";
import extractTokenExpirationDate from "../utils/extractTokenExpirationDate.ts";
import { externalWalletModule, verisModule } from "../clients/verisModule";
import { equalIgnoreCase } from "@unlockdfinance/verislabs-web3/utils";
import { app } from "app.config";
import { SimpleNft } from "./types/nft/INft";

type PricesResponse = {
  result: {
    collection: Address;
    tokenId: string;
    valuation: string;
    ltv: string;
    liquidationThreshold: number;
  }[];
};

type MessageResponse = {
  message: string;
};

type ValidateResponse = {
  token: string;
};

type ServerResponse = {
  items: LoanFromServer[];
  pagination: PaginationFromServer;
};

export type PaginationFromServer = {
  offset: number;
  limit: number;
};

type MarketItemsResponse = {
  items: MarketItemFromServer[];
  pagination: PaginationFromServer;
};

export type CollectionDataFromServer = {
  [key: `0x${string}`]: {
    nftCount: number;
    max: string;
    min: string;
    tvl: string;
  };
};

export type MarketItemFromServer = {
  type: MarketItemType;
  id?: Address;
  loan: {
    id: Address;
    underlyingAsset: Address;
    nfts: {
      collection: Address;
      tokenId: string;
      valuation: string;
      ltv: string;
    }[];
  };
  nft: {
    collection: Address;
    tokenId: string;
    valuation: string;
    ltv: string;
  };
  owner: Address;
  bids: {
    bidAmount: string;
    bidder: Address;
    amountOfDebt: string;
    amountToPay: string;
  }[];
  endTime: number;
  assetId: Address;
};

export type LoanFromServer = {
  loanId: Address;
  healthFactor: string;
  debt: string;
  assets: {
    address: Address;
    tokenId: string;
    valuation: string;
    ltv: string;
  }[];
};

export type TokenSubCallback = (token: string | null) => void;

class UnlockdService {
  private _token: {
    value: string;
    expiresAt: number;
    address: Address;
  } | null = null;
  baseUrl: string;
  private storage = verisModule.storage.local;
  private tokenSubscriptions: Map<Address, TokenSubCallback[]> = new Map();

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;

    const eventHandler = (event: StorageEvent) => {
      if (event.key === "store" || event.key === null) {
        if (event.newValue === null) {
          this.token = null;
        } else if (externalWalletModule.address) {
          const tokenValue = JSON.parse(event.newValue)[app.CHAIN.name].account[
            externalWalletModule.address
          ].token;

          if (tokenValue && tokenValue !== this.token) {
            this.token = tokenValue;
          }
        }
      }
    };

    const cleanUp = () => {
      if (process.browser) {
        window.removeEventListener("storage", eventHandler);
        window.removeEventListener("beforeunload", cleanUp);
      }
    };

    if (process.browser) {
      window.addEventListener("storage", eventHandler);
      window.addEventListener("beforeunload", cleanUp);
    }
  }

  get token() {
    const address = externalWalletModule.address;

    if (!address) {
      throw new Error("No address in context");
    }

    if (this._token && !equalIgnoreCase(this._token.address, address)) {
      this._token = null;
    }

    const tokenFromStorage: string = this.storage!.get(
      `account.${address}.token`,
      null
    );

    if (tokenFromStorage) {
      const date = extractTokenExpirationDate(tokenFromStorage);

      this._token = {
        value: tokenFromStorage,
        expiresAt: date.getTime(),
        address,
      };
    } else {
      this._token = null;
    }

    if (this._token && this._token.expiresAt < Date.now()) {
      this._token = null;

      this.storage!.set(`account.${address}.token`, "");
    }

    if (this._token) {
      return this._token.value;
    }

    return null;
  }

  set token(token: string | null) {
    const address = externalWalletModule.address;

    if (!address) {
      throw new Error("No address in context");
    }

    if (token) {
      const date = extractTokenExpirationDate(token);

      this._token = {
        value: token,
        expiresAt: date.getTime(),
        address,
      };

      this.storage!.set(`account.${address}.token`, token);

      if (this.tokenSubscriptions.has(address)) {
        this.tokenSubscriptions.get(address)!.forEach((callback) => {
          callback(token);
        });
      }
    } else {
      this._token = null;

      this.storage!.set(`account.${address}.token`, "");

      if (this.tokenSubscriptions.has(address)) {
        this.tokenSubscriptions.get(address)!.forEach((callback) => {
          callback(null);
        });
      }
    }
  }

  async retrieveOrders(
    limit: number,
    offset: number,
    filter: MarketplaceFilter
  ) {
    let url = `${this.baseUrl}/orders?limit=${limit}&offset=${offset}&order=${filter.sort}&ended=${filter.ended}`;

    if (filter.collections !== undefined) {
      url += `&filter[]=${app.COLLECTIONS[
        filter.collections!
      ].address.toLowerCase()}`;
    }

    const res = await fetch(url);

    if (!res.ok) {
      throw new Error("failed on getting orders from server");
    }

    const { items: orders, pagination } =
      (await res.json()) as MarketItemsResponse;

    return { orders, pagination };
  }

  async retrieveOrder(
    collection: Address,
    tokenId: string
  ): Promise<MarketItemFromServer | null> {
    let url = `${this.baseUrl}/order?collection=${collection}&tokenId=${tokenId}`;

    const res = await fetch(url);

    if (!res.ok) {
      if (res.status === 400) return null;

      throw new Error("failed on getting order from server");
    }

    const order = (await res.json()) as MarketItemFromServer;

    return order;
  }

  async retrieveNearLiquidationList(offset: number, limit: number) {
    const res = await fetch(
      `${this.baseUrl}/healthFactor?offset=${offset}&limit=${limit}`
    );

    if (!res.ok) {
      throw new Error("failed on getting health factor list from server");
    }

    return (await res.json()) as ServerResponse;
  }

  async getCollectionsData() {
    const res = await fetch(`${this.baseUrl}/collections-summary`);

    if (!res.ok) {
      throw new Error("Error fetching collections data");
    }

    return (await res.json()) as CollectionDataFromServer;
  }

  async getAuthMessage(address: Address) {
    const response = await fetch(`${this.baseUrl}/auth/${address}/message`);

    if (!response.ok) {
      throw new Error("error fetching message from unlockd api");
    }

    const { message } = (await response.json()) as MessageResponse;

    return message;
  }

  async sendSignature(address: Address, signature: string) {
    const response = await fetch(`${this.baseUrl}/auth/${address}/validate`, {
      method: "POST",
      body: JSON.stringify({ signature }),
      headers: { "Content-Type": "application/json" },
    });

    if (!response.ok) {
      throw new Error("error validating message from unlockd api");
    }

    const { token } = (await response.json()) as ValidateResponse;

    return token;
  }

  async getNftsPrices(nfts: (SimpleNft & { underlyingAsset: Address })[]) {
    const body = {
      nfts: nfts.map(({ collection, tokenId, underlyingAsset }) => ({
        collection: collection.toLowerCase(),
        tokenId,
        underlyingAsset: underlyingAsset.toLowerCase(),
      })),
    };

    const response = await fetch(`${this.baseUrl}/prices`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: { "Content-Type": "application/json" },
    });

    if (!response.ok) {
      throw new Error("failed on prices endpoint from server");
    }

    const { result }: PricesResponse = await response.json();

    return result.map(
      ({ tokenId, ltv, liquidationThreshold, valuation, ...rest }) => ({
        tokenId,
        ltv: BigInt(ltv),
        valuation: BigInt(valuation),
        liquidationThreshold: BigInt(liquidationThreshold),
        ...rest,
      })
    );
  }

  async getBidOnAuctionedSignature(loanId: Address, nft: SimpleNft) {
    if (!this.token) {
      throw new Error("No token");
    }

    const url = `${this.baseUrl}/signature/auction/bid`;

    const body = {
      loanId,
      nft: {
        collection: nft.collection.toLowerCase(),
        tokenId: nft.tokenId,
      },
    };

    const res = await fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
    });

    if (!res.ok) {
      throw new Error("failed on getting bid signature from server");
    }

    return (await res.json()) as { signature: any; data: any };
  }

  async getAuctionClaimSignature(loanId: Address, nft: SimpleNft) {
    {
      if (!this.token) {
        throw new Error("No token");
      }

      const url = `${this.baseUrl}/signature/auction/claim`;

      const body = {
        loanId,
        nft: {
          collection: nft.collection.toLowerCase(),
          tokenId: nft.tokenId,
        },
      };

      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`,
        },
      });

      if (!res.ok) {
        throw new Error("failed on getting bid signature from server");
      }

      return (await res.json()) as { signature: any; data: any };
    }
  }

  async getBorrowSignature(
    underlyingAsset?: Address,
    nfts?: SimpleNft[],
    loanId?: Address
  ): Promise<{ signature: any; data: any }> {
    if (!this.token) {
      throw new Error("No token");
    }

    const url = `${this.baseUrl}/signature/loan/borrow`;

    const body: {
      nfts?: { collection: string; tokenId: string }[];
      loanId?: string;
      underlyingAsset?: Address;
    } = {};

    if (nfts) {
      body.nfts = nfts.map(({ collection, tokenId }) => ({
        collection: collection.toLowerCase(),
        tokenId: tokenId,
      }));
    }

    if (loanId) {
      body.loanId = loanId.toLowerCase();
    }

    if (underlyingAsset) {
      body.underlyingAsset = underlyingAsset.toLowerCase() as Address;
    }

    const res = await fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
    });

    if (!res.ok) {
      throw new Error("failed on getting borrow signature from server");
    }

    return (await res.json()) as { signature: any; data: any };
  }

  async getMarketSignature(nft: SimpleNft) {
    if (!this.token) {
      throw new Error("No token");
    }

    const url = `${this.baseUrl}/signature/market`;

    const body = {
      collection: nft.collection.toLowerCase(),
      tokenId: nft.tokenId,
    };

    const res = await fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
    });

    if (!res.ok) {
      throw new Error("failed on getting list for sale signature from server");
    }

    return (await res.json()) as { signature: any; data: any };
  }

  async getRedeemSignature(loanId: Address) {
    if (!this.token) {
      throw new Error("No token");
    }

    const url = `${this.baseUrl}/signature/redeem`;

    const body = {
      loanId,
    };

    const res = await fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
    });

    if (!res.ok) {
      throw new Error("failed on getting redeem signature from server");
    }

    return (await res.json()) as { signature: any; data: any };
  }

  async getRepaySignature(loanId: Address, nftsToRemove?: SimpleNft[]) {
    if (!this.token) {
      throw new Error("No token");
    }

    const url = `${this.baseUrl}/signature/loan/repay`;

    const body: {
      loanId: string;
      nfts?: { collection: string; tokenId: string }[];
    } = {
      loanId: loanId.toLowerCase(),
    };

    if (nftsToRemove && nftsToRemove.length > 0) {
      body.nfts = nftsToRemove.map(({ collection, tokenId }) => ({
        collection: collection.toLowerCase(),
        tokenId: tokenId,
      }));
    }

    const res = await fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
    });

    if (!res.ok) {
      throw new Error("failed on getting repay signature from server");
    }

    return (await res.json()) as { signature: any; data: any };
  }

  async getSellNowSignature(loanId: Address, nft: SimpleNft) {
    if (!this.token) {
      throw new Error("No token");
    }

    const url = `${this.baseUrl}/signature/sellnow`;

    const body = {
      loanId,
      nft: {
        collection: nft.collection.toLowerCase(),
        tokenId: nft.tokenId,
      },
    };

    const res = await fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
    });

    if (!res.ok) {
      throw new Error("failed on getting sell now signature from server");
    }

    return (await res.json()) as { signature: any; data: any };
  }

  subscribeToken(address: Address, callback: TokenSubCallback) {
    if (!this.tokenSubscriptions.has(address)) {
      this.tokenSubscriptions.set(address, []);
    }

    this.tokenSubscriptions.get(address)!.push(callback);

    return () => this.unsubscribeToken(address, callback);
  }

  unsubscribeToken(address: Address, callback: TokenSubCallback) {
    if (this.tokenSubscriptions.has(address)) {
      const callbacks = this.tokenSubscriptions.get(address)!;

      const index = callbacks.indexOf(callback);

      if (index !== -1) {
        callbacks.splice(index, 1);
      }
    }
  }
}

export default new UnlockdService("/api/unlockd-api");
