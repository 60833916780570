import { Address, WatchContractEventReturnType } from "viem";
import { uTokenFactoryAbi } from "./abis/uTokenFactoryAbi";
import { OptionsWriteMethod, Output } from "@unlockdfinance/verislabs-web3";
import { externalWalletModule } from "../clients/verisModule";
import AbstractContract from "./AbstractContract";

export type SupplyTopics = {
  user?: `0x${string}` | `0x${string}`[] | undefined;
  onBehalfOf?: `0x${string}` | `0x${string}`[] | undefined;
  underlyingAsset?: `0x${string}` | `0x${string}`[] | undefined;
  amount?: bigint | undefined;
};

export type WithdrawTopics = {
  user?: `0x${string}` | undefined;
  underlyingAsset?: `0x${string}` | undefined;
  amount?: bigint | undefined;
  to?: `0x${string}` | undefined;
};

export enum ReserveState {
  STOPPED, // No supply, No borrow
  FREEZED, // No supply, No withdraw , No borrow, No repay
  ACTIVE, // All OK
}

export type ReserveData = {
  config: any;
  liquidityIndex: bigint;
  variableBorrowIndex: bigint;
  currentLiquidityRate: bigint;
  currentVariableBorrowRate: bigint;
  underlyingAsset: Address;
  scaledTokenAddress: Address;
  interestRateAddress: Address;
  strategyAddress: Address;
  lastUpdateTimestamp: number;
  // reserveType: number;
  // reserveState: ReserveState;
  // reserveFactor: number;
  // decimals: number;
};

export default class UTokenFactoryContract extends AbstractContract<
  typeof uTokenFactoryAbi
> {
  constructor(address: Address) {
    super({ address }, uTokenFactoryAbi);
  }

  async balanceOf(underlyingAsset: Address, address: Address): Promise<bigint> {
    return (await this.viemReadContract).read.getBalanceByUser([
      underlyingAsset,
      address,
    ]);
  }

  async scaledBalanceOf(
    underlyingAsset: Address,
    address: Address
  ): Promise<bigint> {
    return (await this.viemReadContract).read.getScaledBalanceByUser([
      underlyingAsset,
      address,
    ]);
  }

  async totalAvailableSupply(underlyingAsset: Address): Promise<bigint> {
    return (await this.viemReadContract).read.totalAvailableSupply([
      underlyingAsset,
    ]);
  }

  async totalSupply(underlyingAsset: Address): Promise<bigint> {
    return (await this.viemReadContract).read.totalSupply([underlyingAsset]);
  }

  async getReserveData(underlyingAsset: Address): Promise<ReserveData> {
    return (await this.viemReadContract).read.getReserveData([underlyingAsset]);
  }

  // returns a tuple with booleans that represent the following states:
  // active
  // frozen
  // paused
  async getFlags(underlyingAsset: Address) {
    return (await this.viemReadContract).read.getFlags([underlyingAsset]);
  }

  async getDebtFromLoanId(underlyingAsset: Address, loanId: Address) {
    return (await this.viemReadContract).read.getDebtFromLoanId([
      underlyingAsset,
      loanId,
    ]);
  }

  async getScaledTotalDebtMarket(underlyingAsset: Address) {
    return (await this.viemReadContract).read.getScaledTotalDebtMarket([
      underlyingAsset,
    ]);
  }

  async getScaledToken(underlyingAsset: Address) {
    return (await this.viemReadContract).read.getScaledToken([underlyingAsset]);
  }

  async getScaledTotalDebtFromUser(
    underlyingAsset: Address,
    user: Address
  ): Promise<bigint> {
    return (await this.viemReadContract).read.getScaledTotalDebtFromUser([
      underlyingAsset,
      user,
    ]);
  }

  deposit(
    underlyingAsset: Address,
    amount: bigint,
    onBehalfOf: Address,
    options?: OptionsWriteMethod
  ): Promise<Output<void>> {
    return externalWalletModule.handleViemTransaction(
      async () =>
        // @ts-ignore
        (await this.viemWriteContract).write.deposit([
          underlyingAsset,
          amount,
          onBehalfOf,
        ]),
      options
    );
  }

  withdraw(
    underlyingAsset: Address,
    amount: bigint,
    to: Address,
    options?: OptionsWriteMethod
  ) {
    return externalWalletModule.handleViemTransaction(
      async () =>
        // @ts-ignore
        (await this.viemWriteContract).write.withdraw([
          underlyingAsset,
          amount,
          to,
        ]),
      options
    );
  }

  async onDeposit(
    address: Address,
    onLogs: (depositTopics: SupplyTopics) => void
  ): Promise<WatchContractEventReturnType> {
    return (await this.viemReadContract).watchEvent.Deposit(
      { onBehalfOf: address },
      { onLogs: (logs) => onLogs(logs[0].args) }
    );
  }

  async onWithdraw(
    address: Address,
    onLogs: (withdrawTopics: WithdrawTopics) => void
  ): Promise<WatchContractEventReturnType> {
    return (await this.viemReadContract).watchEvent.Withdraw(
      { to: address },
      { onLogs: (logs) => onLogs(logs[0].args) }
    );
  }
}
