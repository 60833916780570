import { multiLoanBorrowValues } from "@unlockdfinance/unlockd-math";
import parseNftsToUseMaths from "../nfts/parseNftsToUseMaths";
import { INft } from "logic/types/nft/INft";

export const calculateAvailableToBorrow = (
  nfts: INft[],
  debt: bigint
): bigint => {
  const _nfts = nfts.filter((nft) => nft.availableToBorrow > BigInt(0));

  if (_nfts.length === 0) {
    return BigInt(0);
  }

  const maximumAmount = BigInt(
    multiLoanBorrowValues(parseNftsToUseMaths(_nfts)).availableToBorrow
  );

  if (debt > maximumAmount) return BigInt(0);

  return maximumAmount - debt;
};
