import { useState } from "react";

export const useAmountSelection = (max?: bigint) => {
  const [amount, setAmount] = useState<bigint>();
  const [amountError, setAmountError] = useState<Error | null>(null);

  const validateAmount = (_amount?: bigint): Error | null => {
    let error: Error | null = null;

    const amountToCheck = _amount || amount;

    try {
      if (amountToCheck === undefined) {
        throw new Error("Insert an amount");
      }

      if (amountToCheck < BigInt(0)) {
        throw new Error("Amount cannot be negative");
      }

      if (max !== undefined && amountToCheck > max) {
        throw new Error("Amount cannot be greater than the balance");
      }
    } catch (err) {
      error = err as Error;
    }

    setAmountError(error);

    return error;
  };

  const resetAmount = () => setAmount(BigInt(0));

  const handleAmountChange = (amount: bigint) => {
    if (amountError) {
      validateAmount(amount);
    }

    setAmount(amount);
  };

  return {
    amount,
    resetAmount,
    handleAmountChange,
    validateAmount,
    amountError,
  };
};
