import type { Address } from "viem";
import { delegationWalletFactoryAbi } from "./abis/delegationWalletFactoryAbi";
import { OptionsWriteMethod, Output } from "@unlockdfinance/verislabs-web3";
import { externalWalletModule } from "../clients/verisModule";
import AbstractContract from "./AbstractContract";
import { app } from "app.config";

const { delegationController } = app.SMART_ACCOUNTS_CONFIG;

export default class DelegationWalletFactoryContract extends AbstractContract<
  typeof delegationWalletFactoryAbi
> {
  constructor(address: Address) {
    super({ address }, delegationWalletFactoryAbi);
  }

  async deploy(
    externalAccount: Address,
    options?: OptionsWriteMethod
  ): Promise<Output<void>> {
    return externalWalletModule.handleViemTransaction(
      async () =>
        (await this.viemWriteContract).write.deploy([delegationController], {
          account: externalAccount,
          chain: undefined,
        }),
      options
    );
  }
}
