import { INft } from "logic/types/nft/INft";
import { useEffect, useState } from "react";

export const useNftTitle = (nft: INft) => {
  const [title, setTitle] = useState<string>();

  useEffect(() => {
    handleGetNftTitle();
  }, [nft]);

  const handleGetNftTitle = async () => {
    const title = await nft.title;
    setTitle(title);
  };

  return title;
};
