import { VerisModule } from "@unlockdfinance/verislabs-web3";
import { app } from "../app.config";
import { alchemyApiKey } from "./alchemy";

export const verisModule = new VerisModule(
  app.CHAIN.name,
  alchemyApiKey,
  app.WALLET_CONNECT.projectId
);

export const externalWalletModule = verisModule.walletModule;
