import { Address } from "viem";
import { OptionsWriteMethod, Output } from "@unlockdfinance/verislabs-web3";
import Erc20Contract from "contracts/Erc20Contract";

export const approveErc20 = (
  erc20Address: Address,
  spender: Address,
  amount: bigint,
  options?: OptionsWriteMethod
): Promise<Output<void>> => {
  const contract = new Erc20Contract(erc20Address);

  return contract.approve(spender, amount, options);
};
