import { equalIgnoreCase } from "@unlockdfinance/verislabs-web3/utils";
import { Erc20Currency, NativeCurrency } from "./types/currency/Currency";
import { app } from "app.config";
import { Address } from "@unlockdfinance/verislabs-web3";

class CurrenciesModule {
  currencies: (NativeCurrency | Erc20Currency)[];
  tokens: (NativeCurrency | Erc20Currency)[];

  // TODO: not create more than one currency when is repeated in currencies and tokens
  constructor() {
    this.currencies = app.CURRENCIES.map((currencyData) =>
      currencyData.type === "erc20" && currencyData.address
        ? // @ts-ignore
          new Erc20Currency(currencyData)
        : new NativeCurrency(currencyData)
    );

    this.tokens = app.TOKENS.map((currencyData) =>
      currencyData.type === "erc20" && currencyData.address
        ? // @ts-ignore
          new Erc20Currency(currencyData)
        : new NativeCurrency(currencyData)
    );
  }

  get erc20Currencies(): Erc20Currency[] {
    const erc20Currencies: Erc20Currency[] = [];

    this.currencies.forEach((currency) => {
      if (currency instanceof Erc20Currency) {
        erc20Currencies.push(currency);
      }
    });

    return erc20Currencies;
  }

  get wethCurrency(): Erc20Currency {
    return this.currencies.find(
      (currency) => currency.name === "weth"
    )! as Erc20Currency;
  }

  get defaultCurrency(): Erc20Currency {
    return this.currencies.find(
      (currency) => currency.name === "usdc"
    )! as Erc20Currency;
  }

  get nativeCurrency(): NativeCurrency {
    return this.currencies.find(
      (currency) => currency instanceof NativeCurrency
    ) as NativeCurrency;
  }

  getErc20CurrencyByAddress(address: Address): Erc20Currency | null {
    const currency = this.erc20Currencies.find((currency) =>
      equalIgnoreCase(currency.address, address)
    );

    return currency || null;
  }
}

export default new CurrenciesModule();
