import { Address, maxUint256 } from "viem";
import {
  HealthFactorVariations,
  calculateHealthFactor,
} from "../../helpers/math/calculateHealthFactor";
import determineLoanStatus from "../../helpers/loans/determineLoanStatus";
import {
  actionContract,
  marketContract,
  sellNowContract,
} from "../../../contracts";
import { calculateMinimumRepay } from "@unlockdfinance/unlockd-math";
import { MarketItemType } from "../../../contracts/MarketContract";
import unlockdService from "../../UnlockdService";
import { OptionsWriteMethod, Output } from "@unlockdfinance/verislabs-web3";
import {
  areTheSameNft,
  equalIgnoreCase,
} from "@unlockdfinance/verislabs-web3/utils";
import { app } from "app.config";
import { INft, SimpleNft } from "../nft/INft";
import {
  calculateAvailableToBorrow,
  calculateLiquidationPrice,
  calculateLoanValuation,
  calculateLtv,
} from "logic/helpers/math";

export enum LoanStatus {
  TO_REDEEM,
  TO_REPAY,
  ONLY_REPAY,
  TO_BORROW,
}

export class Loan {
  readonly id: Address;
  readonly nfts: INft[];
  readonly borrower: Address;
  readonly currentBorrowAmount: bigint;
  readonly underlyingAsset: Address;
  readonly status: LoanStatus;
  readonly valuation: bigint;
  readonly healthFactor: bigint;
  readonly availableToBorrow: bigint;
  readonly ltv: bigint;
  readonly liquidationPrice: bigint;
  readonly isAuctioned: boolean = false;
  readonly lastSalePrice?: bigint;

  // example: 0.85 is expressed as 8500n
  // at the beginning the liquidation threshold
  // will be 8500 for all the nfts
  readonly liquidationThreshold: bigint;

  static async borrow(
    underlyingAsset: Address,
    amount: bigint,
    nfts: INft[],
    options?: OptionsWriteMethod
  ) {
    options?.onServerSignPending?.();

    const { data, signature } = await unlockdService.getBorrowSignature(
      underlyingAsset,
      nfts
    );

    return actionContract.borrow(
      amount,
      this.parseNftsToBorrow(nfts),
      data,
      signature,
      options
    );
  }

  private static parseNftsToBorrow(nfts: SimpleNft[]) {
    return nfts.map(({ collection, tokenId }) => ({
      collection,
      tokenId: BigInt(tokenId),
    }));
  }

  constructor({
    borrower,
    currentBorrowAmount,
    id,
    nfts,
    lastSalePrice,
    underlyingAsset,
  }: {
    id: Address;
    nfts: INft[];
    borrower: Address;
    underlyingAsset: Address;
    currentBorrowAmount: bigint;
    lastSalePrice?: bigint;
  }) {
    this.id = id;
    this.nfts = nfts;
    this.borrower = borrower;
    this.currentBorrowAmount = currentBorrowAmount;
    this.lastSalePrice = lastSalePrice;
    this.underlyingAsset = underlyingAsset;

    this.valuation = calculateLoanValuation(this.nfts);

    this.liquidationThreshold = app.DEFAULT_LIQ_THRESHOLD_VALUE;

    this.healthFactor = calculateHealthFactor(
      this.valuation,
      this.currentBorrowAmount,
      this.liquidationThreshold
    );

    this.availableToBorrow = calculateAvailableToBorrow(
      this.nfts,
      this.currentBorrowAmount
    );

    this.ltv = calculateLtv(this.nfts);

    this.status = determineLoanStatus(
      this.currentBorrowAmount,
      this.healthFactor,
      this.availableToBorrow,
      this.isAuctioned
    );

    // @ts-ignore
    this.liquidationPrice = calculateLiquidationPrice(
      this.currentBorrowAmount,
      this.liquidationThreshold
    );
  }

  get currency() {
    return this.nfts[0].currency;
  }

  get isRemoveNftsAvailable() {
    return (
      this.currentBorrowAmount === BigInt(0) ||
      (this.isMulticollateral &&
        (this.status === LoanStatus.ONLY_REPAY ||
          this.status === LoanStatus.TO_BORROW))
    );
  }

  isListForSaleAvailable(nft: INft) {
    return !this.isHfBelowOne && !this.isAuctioned && !nft.isListed;
  }

  get isInstantSellAvailable() {
    return !this.isAuctioned;
  }

  get isMulticollateral() {
    return this.nfts.length > 1;
  }

  get isHfBelowOne() {
    return this.healthFactor < 1000;
  }

  apy(): Promise<number> {
    return this.currency.getBorrowApy();
  }

  calculateHealthFactor(variations: HealthFactorVariations): bigint {
    return calculateHealthFactor(
      this.valuation,
      this.currentBorrowAmount,
      this.liquidationThreshold,
      variations
    );
  }

  calculateIfInstantSellIsPossible(nftToSell: INft, price: bigint): boolean {
    const amountToReceive = this.calculateAmuntToReceiveOnInstantSell(
      nftToSell,
      price
    );

    return !(amountToReceive < BigInt(0));
  }

  calculateAmuntToReceiveOnInstantSell(nftToSell: INft, price: bigint): bigint {
    const index = this.nfts.findIndex((nft) => areTheSameNft(nftToSell, nft));

    if (index === -1) {
      throw new Error("nft to sell is not included in this loan");
    }

    const minToRepay = calculateMinimumRepay({
      initialLoans: this.nfts,
      indicesToDelete: [index],
      totalDebt: this.currentBorrowAmount,
    });

    return price - minToRepay;
  }

  calculateAvailableToBorrow(variations: {
    nftsToRemove?: INft[];
    nftsToAdd?: INft[];
    debt?: bigint;
  }): bigint {
    const nfts: (INft | INft)[] = [...this.nfts];
    let totalDebt = this.currentBorrowAmount;

    if (variations.debt) totalDebt += variations.debt;

    if (variations.nftsToAdd) nfts.push(...variations.nftsToAdd);

    const maximumAmount = nfts.reduce((acc, nft) => {
      if (variations.nftsToRemove) {
        const isBeingRemoved = variations.nftsToRemove.some(
          ({ collection, tokenId }) =>
            equalIgnoreCase(collection, nft.collection) &&
            tokenId === nft.tokenId
        );

        if (!isBeingRemoved) {
          acc += nft.availableToBorrow;
        }
      } else {
        acc += nft.availableToBorrow;
      }

      return acc;
    }, BigInt(0));

    if (totalDebt > maximumAmount) return BigInt(0);

    return maximumAmount - totalDebt;
  }

  calculateMinToRepay(nftsToRemove: INft[]): bigint {
    const indexes: number[] = nftsToRemove.map((nftToRemove) => {
      const index = this.nfts.findIndex((nft) =>
        areTheSameNft(nft, nftToRemove)
      );

      if (index === -1) {
        throw new Error("Nft provided is not included in the loan");
      }

      return index;
    });

    return calculateMinimumRepay({
      initialLoans: this.nfts,
      indicesToDelete: indexes,
      totalDebt: this.currentBorrowAmount,
    });
  }

  async repay(
    amount: bigint,
    options?: OptionsWriteMethod
  ): Promise<Output<void>> {
    options?.onServerSignPending?.();
    const fullyRepay = amount === this.currentBorrowAmount;

    const nftsToRemove = fullyRepay ? this.nfts : undefined;

    const correctedAmount = fullyRepay ? maxUint256 : amount;

    const { data, signature } = await unlockdService.getRepaySignature(
      this.id,
      nftsToRemove
    );

    return actionContract.repay(correctedAmount, data, signature, options);
  }

  async borrowMore(
    amount: bigint,
    newNfts?: INft[],
    options?: OptionsWriteMethod
  ): Promise<Output<void>> {
    options?.onServerSignPending?.();

    const { data, signature } = await unlockdService.getBorrowSignature(
      undefined,
      newNfts,
      this.id
    );

    const nfts =
      newNfts?.map(({ collection, tokenId }) => ({
        collection,
        tokenId: BigInt(tokenId),
      })) || [];

    return actionContract.borrow(amount, nfts, data, signature, options);
  }

  async addNfts(
    nfts: SimpleNft[],
    options?: OptionsWriteMethod
  ): Promise<Output<void>> {
    options?.onServerSignPending?.();

    const { data, signature } = await unlockdService.getBorrowSignature(
      undefined,
      nfts,
      this.id
    );

    return actionContract.borrow(
      BigInt(0),
      Loan.parseNftsToBorrow(nfts),
      data,
      signature,
      options
    );
  }

  async removeNftsAndRepay(
    nfts: SimpleNft[],
    amountToRepay?: bigint,
    options?: OptionsWriteMethod
  ): Promise<Output<void>> {
    options?.onServerSignPending?.();
    let correctedAmount: bigint;

    const isFullyRepay = amountToRepay === this.currentBorrowAmount;

    const correctedNfts = isFullyRepay ? this.nfts : nfts;

    if (amountToRepay) {
      correctedAmount = isFullyRepay ? maxUint256 : amountToRepay;
    } else {
      correctedAmount = BigInt(0);
    }

    const { data, signature } = await unlockdService.getRepaySignature(
      this.id,
      correctedNfts
    );

    return actionContract.repay(correctedAmount, data, signature, options);
  }

  async instantSell(
    nft: INft,
    options?: OptionsWriteMethod
  ): Promise<Output<void>> {
    options?.onServerSignPending?.();

    const { data, signature } = await unlockdService.getSellNowSignature(
      this.id,
      nft
    );

    return sellNowContract.sellNow(nft, data, signature, options);
  }

  async listForSale(
    nft: INft,
    marketType: MarketItemType,
    debtListing: bigint,
    finishDate: bigint,
    fixedPrice?: bigint,
    _startingPrice?: bigint,
    options?: OptionsWriteMethod
  ): Promise<Output<void>> {
    this.validateListForSaleInputs(
      marketType,
      debtListing,
      fixedPrice,
      _startingPrice
    );

    options?.onServerSignPending?.();

    const { data, signature } = await unlockdService.getMarketSignature(nft);

    const startingPrice =
      marketType === MarketItemType.TYPE_FIXED_PRICE
        ? fixedPrice!
        : _startingPrice!;

    const endAmount =
      marketType === MarketItemType.TYPE_AUCTION ||
      marketType === MarketItemType.TYPE_LIQUIDATION_AUCTION
        ? BigInt(0)
        : fixedPrice!;

    const startTime = BigInt(Math.floor(Date.now() / 1000));

    const endTime =
      marketType === MarketItemType.TYPE_FIXED_PRICE
        ? BigInt(Math.floor(new Date("12/31/2099").getTime() / 1000))
        : finishDate;

    return marketContract.listForSale(
      this.underlyingAsset,
      marketType,
      startingPrice,
      endAmount,
      startTime,
      endTime,
      debtListing,
      data,
      signature,
      options
    );
  }

  cancelListForSale(
    nft: INft,
    options?: OptionsWriteMethod
  ): Promise<Output<void>> {
    if (!nft.isCancelListAvailable || !nft.marketItemData) {
      throw new Error("cancel list is not available for this nft");
    }

    return marketContract.cancel(nft.marketItemData.id, options);
  }

  private validateListForSaleInputs(
    marketType: MarketItemType,
    debtListing: bigint,
    fixedPrice?: bigint,
    startingPrice?: bigint
  ): void {
    if (marketType === MarketItemType.TYPE_LIQUIDATION_AUCTION) {
      throw new Error(
        "Liquidation auctions only can be created by the backend"
      );
    } else if (
      (marketType === MarketItemType.TYPE_FIXED_PRICE ||
        marketType === MarketItemType.TYPE_FIXED_PRICE_AND_AUCTION) &&
      fixedPrice === undefined
    ) {
      throw new Error("Fixed price is required for for this type of item");
    } else if (
      (marketType === MarketItemType.TYPE_AUCTION ||
        marketType === MarketItemType.TYPE_FIXED_PRICE_AND_AUCTION) &&
      startingPrice === undefined
    ) {
      throw new Error("Starting price is required for for this type of item");
    }

    if (debtListing < BigInt(0) || debtListing > BigInt(10000)) {
      throw new Error("Debt listing must be between range of 0 to 10000");
    }
  }
}
