import { useCustomWallet } from "components/providers";
import { INft } from "logic/types/nft/INft";
import { useEffect, useState } from "react";
import track from "logic/track";
import { useCustomParams } from "./useCustomParams";

export const useNftSelectionV3 = (
  nfts: INft[],
  {
    blocked = false,
    avoidMultiCurrency = false,
  }: { blocked?: boolean; avoidMultiCurrency?: boolean }
) => {
  const [selectedNfts, setSelectedNfts] = useState<INft[]>([]);
  const { address } = useCustomWallet();

  const {
    getAllValuesFromParam,
    removeAllValuesFromParam,
    removeValuesFromParam,
    toggleParamValue,
    searchParams,
  } = useCustomParams();

  useEffect(() => {
    if (!address) {
      handleResetSelectedNfts();
    } else {
      return () => handleResetSelectedNfts();
    }
  }, [address]);

  useEffect(() => {
    if (!blocked) {
      const nftsFromParams = getAllValuesFromParam("nft");

      if (
        avoidMultiCurrency &&
        nftsFromParams.length !== 0 &&
        selectedNfts.length !== 0
      ) {
        const wrongSelected = nftsFromParams.filter(
          (nft) =>
            selectedNfts[0].currency.name !==
            nfts.find((_nft) => _nft.nftId === nft)?.currency.name
        );

        if (wrongSelected.length > 0) {
          removeValuesFromParam("nft", wrongSelected);

          return;
        }
      }

      setSelectedNfts(nfts.filter((nft) => nftsFromParams.includes(nft.nftId)));
    }
  }, [searchParams, nfts]);

  const toggleSelectedNft = (nft: INft) => {
    if (blocked) return;

    toggleParamValue("nft", nft.nftId);
  };

  const handleResetSelectedNfts = () => {
    removeAllValuesFromParam("nft");
  };

  return { handleResetSelectedNfts, toggleSelectedNft, selectedNfts };
};
