import { Address } from "viem";
import { externalWalletModule } from "../clients/verisModule";
import Erc20Contract from "contracts/Erc20Contract";

export const getAllowance = (
  erc20Address: Address,
  spender: Address
): Promise<bigint> => {
  const contract = new Erc20Contract(erc20Address);

  return contract.allowance(externalWalletModule.address!, spender);
};
