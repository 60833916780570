import { Address } from "viem";
import { auctionContract, marketContract } from "../../../contracts";
import { AsyncReturnType } from "../AsyncReturnType";
import { Loan } from "./Loan";
import { OptionsWriteMethod, Output } from "@unlockdfinance/verislabs-web3";
import unlockdService from "logic/UnlockdService";
import createAssetId from "logic/helpers/createAssetId";
import { INft } from "../nft/INft";

type RedeemData = {
  totalAmount: bigint;
  totalDebt: bigint;
  bidderBonus: bigint;
};

type OrderFromChain = AsyncReturnType<typeof marketContract.getOrder>;

export default class LoanAuctioned extends Loan {
  readonly redeemEnd: number;
  readonly isAuctioned: boolean = true;
  private orderFromChain?: OrderFromChain;
  private redeemData?: RedeemData;

  constructor({
    borrower,
    currentBorrowAmount,
    id,
    redeemEnd,
    nfts,
    underlyingAsset,
    lastSalePrice,
  }: {
    id: Address;
    nfts: INft[];
    borrower: Address;
    underlyingAsset: Address;
    currentBorrowAmount: bigint;
    redeemEnd: number;
    lastSalePrice?: bigint;
  }) {
    super({
      id,
      nfts,
      borrower,
      underlyingAsset,
      currentBorrowAmount,
      lastSalePrice,
    });

    this.redeemEnd = redeemEnd;
  }

  get auctionFinished(): boolean {
    return this.redeemEnd < Date.now();
  }

  get bidFine(): Promise<bigint> {
    return new Promise(async (resolve) => {
      const { bidderBonus } = await this.getRedeemData();
      resolve(bidderBonus);
    });
  }

  get redeemAmount(): Promise<bigint> {
    return new Promise(async (resolve) => {
      const { totalAmount } = await this.getRedeemData();
      resolve(totalAmount);
    });
  }

  private async getRedeemData(): Promise<RedeemData> {
    if (this.redeemData) return this.redeemData;

    const [totalAmount, totalDebt, bidderBonus] =
      await auctionContract.getAmountToReedem(this.id, this.assetIds);

    // TODO: check this
    this.redeemData = {
      totalAmount,
      totalDebt,
      bidderBonus,
    };

    return this.redeemData;
  }

  get assetIds(): Address[] {
    return this.nfts.map((nft) => createAssetId(nft.collection, nft.tokenId));
  }

  async redeem(options?: OptionsWriteMethod): Promise<Output<void>> {
    options?.onServerSignPending?.();

    const { data, signature } = await unlockdService.getRedeemSignature(
      this.id
    );

    return auctionContract.redeem(data, signature, options);
  }
}
