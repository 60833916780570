import { Address } from "viem";
import { BidArgs, BidTopics } from "./MarketEventsContract";
import { auctionAbi } from "./abis/auctionAbi";
import AbstractContract from "./AbstractContract";
import { WatchContractEventReturnType } from "@wagmi/core";

export type RedeemTopics = {
  loanId?: `0x${string}` | undefined;
  orderId?: `0x${string}` | undefined;
  assetId?: `0x${string}` | undefined;
  amount?: bigint | undefined;
  user?: `0x${string}` | undefined;
};

export type RedeemArgs = {
  loanId?: `0x${string}` | `0x${string}`[] | null | undefined;
  orderId?: `0x${string}` | `0x${string}`[] | null | undefined;
  assetId?: `0x${string}` | `0x${string}`[] | null | undefined;
};

export default class AuctionEventsContract extends AbstractContract<
  typeof auctionAbi
> {
  constructor(address: Address) {
    super({ address }, auctionAbi);
  }

  async onBid(
    args: BidArgs,
    onLogs: (bidTopics: BidTopics) => void
  ): Promise<WatchContractEventReturnType> {
    return (await this.viemReadContract).watchEvent.AuctionBid(args, {
      onLogs: (logs) => onLogs({ ...logs[0].args, isMarketItem: false }),
    });
  }

  async onRedeemed(
    args: RedeemArgs,
    onLogs: (redeemTopics: RedeemTopics) => void
  ): Promise<WatchContractEventReturnType> {
    return (await this.viemReadContract).watchEvent.AuctionRedeem(args, {
      onLogs: (logs) => onLogs(logs[0].args),
    });
  }
}
