import Safe, { EthersAdapter } from "@safe-global/protocol-kit";
import { Address, WalletClient } from "viem";
import { ethers } from "ethers";
import { delegationWalletRegistryContract } from "../contracts";
import { walletClientToSigner } from "../utils/walletClientToSigner";
import { externalWalletModule, verisModule } from "../clients/verisModule";
import { getBalance } from "@wagmi/core";
import {
  Hash,
  OptionsWriteMethod,
  Output,
} from "@unlockdfinance/verislabs-web3";
import { ICurrency, IErc20Currency } from "./types/currency/ICurrency";
import { alchemy } from "clients";
import { AssetTransfersCategory, SortingOrder } from "alchemy-sdk";
import TransferFactory from "./types/transfers/TransferFactory";
import { ITransfer } from "./types/transfers/ITransfer";
import { Erc20Currency } from "./types/currency/Currency";

export type UnlockdWalletSubsCallback = (
  unlockdAddress: Address | null | undefined,
  safeClient: Safe | undefined
) => void;

class UnlockdWalletModule {
  private subscriptions: UnlockdWalletSubsCallback[] = [];
  private _unlockdAddress: Address | null | undefined;
  private _safeClient: Safe | undefined;

  constructor() {
    externalWalletModule.subscribe(async (externalAddress, _, client) => {
      // console.log(
      //   `external wallet subscription on unlockd wallet module triggered. External address ${externalAddress}`
      // );

      this.unlockdAddress = undefined;
      this._safeClient = undefined;

      if (externalAddress) {
        // console.log("getting safe wallet");
        const unlockdAddress = await this.getSafeWallet(externalAddress);
        // console.log(`got safe wallet ${unlockdAddress}`);

        this.unlockdAddress = unlockdAddress;

        if (this.unlockdAddress && client) {
          await this.setSafeClient(this.unlockdAddress, client);
        }

        this.subscriptions.forEach((callback) => {
          callback(this.unlockdAddress, this.safeClient);
        });
      } else {
        this.subscriptions.forEach((callback) => {
          callback(undefined, undefined);
        });
      }
    });
  }

  get unlockdAddress() {
    return this._unlockdAddress;
  }

  get safeClient() {
    return this._safeClient;
  }

  set unlockdAddress(newAddress: Address | null | undefined) {
    this._unlockdAddress = newAddress;
  }

  private async setSafeClient(
    safeAddress: Address,
    client: WalletClient,
    lastTry = false
  ) {
    try {
      const ethAdapter = new EthersAdapter({
        ethers,
        signerOrProvider: walletClientToSigner(client),
      });

      const safeClient: Safe = await Safe.create({
        ethAdapter,
        safeAddress,
      });

      this._safeClient = safeClient;
    } catch (err) {
      // Error message when the it the safe address was just created
      // err.message "SafeProxy contract is not deployed on the current network"
      if (
        !lastTry &&
        err instanceof Error &&
        err.message ===
          "SafeProxy contract is not deployed on the current network"
      ) {
        setTimeout(() => this.setSafeClient(safeAddress, client, true), 10000);
      } else {
        throw err;
      }
    }
  }

  async getSafeWallet(externalAccount: Address): Promise<Address | null> {
    const addresses =
      await delegationWalletRegistryContract.getOwnerWalletAddresses(
        externalAccount
      );

    return addresses[0] || null;
  }

  async getNativeBalance() {
    if (!this.unlockdAddress) {
      throw new Error("No address connected");
    }

    const { value } = await getBalance(verisModule.wagmiConfig, {
      address: this.unlockdAddress,
    });

    return value;
  }

  async sendTransaction(
    to: Address,
    amount: bigint,
    options?: OptionsWriteMethod
  ): Promise<Output<void>> {
    if (!this.safeClient) {
      throw new Error("No safe client connected");
    }

    const safeTransactionData = { to, value: amount.toString(), data: "0x" };

    const safeTransaction = await this.safeClient.createTransaction({
      safeTransactionData,
    });

    options?.onSignaturePending?.();

    const executeTxResponse = await this.safeClient.executeTransaction(
      safeTransaction
    );

    options?.onLoading?.();

    const receipt = await executeTxResponse.transactionResponse?.wait();

    return new Output((receipt?.transactionHash as Hash) || null);
  }

  async getErc20Transfers(currency: IErc20Currency): Promise<ITransfer[]> {
    const address = this.unlockdAddress;

    if (!address) {
      throw new Error("No address connected");
    }

    const [
      { transfers: transfersCredit, pageKey: pageKeyCredit },
      { transfers: transfersDebit, pageKey: pageKeyDebit },
    ] = await Promise.all([
      alchemy.core.getAssetTransfers({
        category: [AssetTransfersCategory.ERC20],
        contractAddresses: [currency.address],
        toAddress: address,
        order: SortingOrder.DESCENDING,
        withMetadata: true,
      }),
      alchemy.core.getAssetTransfers({
        category: [AssetTransfersCategory.ERC20],
        contractAddresses: [currency.address],
        fromAddress: address,
        order: SortingOrder.DESCENDING,
        withMetadata: true,
      }),
    ]);

    const transfers = transfersCredit
      .concat(transfersDebit)
      .sort((a, b) => Number(b.blockNum) - Number(a.blockNum));

    return transfers.map((transfer) =>
      TransferFactory.create(transfer, address)
    );
  }

  async getNativeCurrencyTransfers(): Promise<ITransfer[]> {
    const address = this.unlockdAddress;

    if (!address) {
      throw new Error("No address connected");
    }

    const [
      { transfers: transfersCredit, pageKey: pageKeyCredit },
      { transfers: transfersDebit, pageKey: pageKeyDebit },
    ] = await Promise.all([
      alchemy.core.getAssetTransfers({
        category: [
          AssetTransfersCategory.INTERNAL,
          AssetTransfersCategory.EXTERNAL,
        ],
        toAddress: address,
        order: SortingOrder.DESCENDING,
        withMetadata: true,
      }),
      alchemy.core.getAssetTransfers({
        category: [
          AssetTransfersCategory.INTERNAL,
          AssetTransfersCategory.EXTERNAL,
        ],
        fromAddress: address,
        order: SortingOrder.DESCENDING,
        withMetadata: true,
      }),
    ]);

    const transfers = transfersCredit
      .concat(transfersDebit)
      .sort((a, b) => Number(b.blockNum) - Number(a.blockNum));

    return transfers.map((transfer) =>
      TransferFactory.create(transfer, address)
    );
  }

  async getAssetTransfers(currency: ICurrency): Promise<ITransfer[]> {
    if (currency instanceof Erc20Currency) {
      return this.getErc20Transfers(currency);
    } else {
      return this.getNativeCurrencyTransfers();
    }
  }

  subscribe(callback: UnlockdWalletSubsCallback) {
    this.subscriptions.push(callback);
  }

  unsubscribe(callback: UnlockdWalletSubsCallback) {
    const index = this.subscriptions.findIndex(
      (_callback) => callback === _callback
    );

    if (index > -1) {
      this.subscriptions.splice(index, 1);
    }
  }
}

export default new UnlockdWalletModule();
