import { SafeTransactionDataPartial } from "@safe-global/safe-core-sdk-types";
import { Abi } from "viem";
import unlockdWalletModule from "../UnlockdWalletModule";
import {
  Hash,
  OptionsWriteMethod,
  Output,
} from "@unlockdfinance/verislabs-web3";
import AbstractContract from "../../contracts/AbstractContract";

export default async function handleSafeTransaction<TAbi extends Abi, TData>(
  contract: AbstractContract<TAbi>,
  functionName: string,
  args: any[],
  txOptions?: { value: string },
  options?: OptionsWriteMethod
): Promise<Output<TData>> {
  const data = contract.encodeFunctionData(functionName, args);

  const safeTransactionData: SafeTransactionDataPartial = {
    to: contract.address!,
    data,
    value: txOptions?.value || "0",
  };

  const safeTransaction =
    await unlockdWalletModule.safeClient!.createTransaction({
      safeTransactionData,
    });

  options?.onSignaturePending?.();

  const executeTxResponse =
    await unlockdWalletModule.safeClient!.executeTransaction(safeTransaction);

  options?.onLoading?.();

  const receipt = await executeTxResponse.transactionResponse?.wait();

  return new Output((receipt?.transactionHash as Hash) || null);
}
