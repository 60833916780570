import { Address } from "viem";
import { marketAbi } from "./abis/marketAbi";
import { OptionsWriteMethod, Output } from "@unlockdfinance/verislabs-web3";
import { externalWalletModule } from "../clients/verisModule";
import AbstractContract from "./AbstractContract";

export enum MarketItemType {
  // Auction - hf below 1
  TYPE_LIQUIDATION_AUCTION = "0",
  // Market - only bids
  TYPE_AUCTION = "1",
  // Market - only buy now
  TYPE_FIXED_PRICE = "2",
  // Market - both auction and buy now
  TYPE_FIXED_PRICE_AND_AUCTION = "3",
}

export default class MarketContract extends AbstractContract<typeof marketAbi> {
  constructor(moduleId: string) {
    super({ moduleId }, marketAbi);
  }

  async getOrder(id: Address) {
    return (await this.viemReadContract).read.getOrder([id]);
  }

  async getMinBidPrice(
    id: Address,
    underlyingAsset: Address,
    aggLoanPrice: bigint,
    aggLtv: bigint
  ): Promise<bigint> {
    return (await this.viemReadContract).read.getMinBidPrice([
      id,
      underlyingAsset,
      aggLoanPrice,
      aggLtv,
    ]);
  }

  async getBuyNowPrice(
    id: Address,
    underlyingAsset: Address,
    aggLoanPrice: bigint,
    aggLtv: bigint
  ) {
    return (await this.viemReadContract).read.getBuyNowPrice([
      id,
      underlyingAsset,
      aggLoanPrice,
      aggLtv,
    ]);
  }

  listForSale(
    underlyingAsset: Address,
    marketType: MarketItemType,
    startAmount: bigint,
    endAmount: bigint,
    startTime: bigint,
    endTime: bigint,
    debtToSell: bigint,
    data: any,
    signature: any,
    options?: OptionsWriteMethod
  ): Promise<Output<void>> {
    // startAmount -> If it is an auction, the starting value. If it is fixed, the same value than endAmount
    // endAmount -> The buyout value.
    // start time -> Now timestamp expressed in seconds.
    // endTime -> Expressed in seconds.
    // debt to sell -> Percentage of the total debt. Expressed in centesimals (10000 = 100%)

    return externalWalletModule.handleViemTransaction(
      async () =>
        // @ts-ignore
        (await this.viemWriteContract).write.create([
          underlyingAsset,
          marketType,
          {
            startAmount,
            endAmount,
            startTime,
            endTime,
            debtToSell,
          },
          data,
          signature,
        ]),
      options
    );
  }

  cancel(
    orderId: Address,
    options?: OptionsWriteMethod
  ): Promise<Output<void>> {
    return externalWalletModule.handleViemTransaction(
      // @ts-ignore
      async () => (await this.viemWriteContract).write.cancel([orderId]),
      options
    );
  }

  bid(
    orderId: Address,
    amountToPay: bigint,
    amountOfDebt: bigint,
    data: any,
    signature: any,
    options?: OptionsWriteMethod
  ): Promise<Output<void>> {
    return externalWalletModule.handleViemTransaction(
      async () =>
        // @ts-ignore
        (await this.viemWriteContract).write.bid([
          orderId,
          amountToPay,
          amountOfDebt,
          data,
          signature,
        ]),
      options
    );
  }

  buyNow(
    orderId: Address,
    amountToPay: bigint,
    amountOfDebt: bigint,
    claimOnUWallet: boolean,
    data: any,
    signature: any,
    options?: OptionsWriteMethod
  ): Promise<Output<void>> {
    return externalWalletModule.handleViemTransaction(
      async () =>
        // @ts-ignore
        (await this.viemWriteContract).write.buyNow([
          claimOnUWallet,
          orderId,
          amountToPay,
          amountOfDebt,
          data,
          signature,
        ]),
      options
    );
  }

  claim(
    claimOnUWallet: boolean,
    orderId: Address,
    data: any,
    signature: any,
    options?: OptionsWriteMethod
  ): Promise<Output<void>> {
    return externalWalletModule.handleViemTransaction(
      async () =>
        // @ts-ignore
        (await this.viemWriteContract).write.claim([
          claimOnUWallet,
          orderId,
          data,
          signature,
        ]),
      options
    );
  }
}
