"use client";
import { useEffect, useMemo, useState } from "react";
import { handleErrors } from "../../errors";
import { useErrorAlert } from "./useErrorAlert";
import { useCustomWallet } from "../../components/providers";
import { Address } from "viem";
import { OptionsWriteMethod } from "@unlockdfinance/verislabs-web3";
import { getAllowance } from "logic/getAllowance";
import { approveErc20 } from "logic/approveErc20";
import currenciesModule from "logic/CurrenciesModule";

export const useAllowance = ({
  amount,
  spender,
  erc20Address = currenciesModule.defaultCurrency.address,
}: {
  amount?: bigint;
  spender: Address;
  erc20Address?: Address | Promise<Address>;
}) => {
  const [allowance, setAllowance] = useState<bigint | undefined>();
  const [error, setError] = useState<Error | null>(null);
  useErrorAlert(error);
  const { address } = useCustomWallet();

  useEffect(() => {
    if (address) {
      handleGetAllowance();

      return () => setAllowance(BigInt(0));
    }
  }, [address]);

  const isApproved = useMemo<boolean>(
    () =>
      amount !== undefined && allowance !== undefined && allowance >= amount,
    [allowance, amount]
  );

  const handleGetAllowance = async () => {
    try {
      setAllowance(await getAllowance(await erc20Address, spender));
    } catch (err) {
      setError(handleErrors(err));
    }
  };

  const handleApprove = async (
    _amount?: bigint,
    options?: OptionsWriteMethod
  ) => {
    const correctedAmount = _amount || amount;

    if (correctedAmount !== undefined && correctedAmount > BigInt(0)) {
      await approveErc20(await erc20Address, spender, correctedAmount, options);

      setAllowance((prevValue) => (prevValue || BigInt(0)) + correctedAmount);

      setTimeout(() => handleGetAllowance(), 10000);
    }
  };

  return { allowance, handleApprove, isApproved };
};
