import { LoanStatus } from "../../types/loan/Loan";

export default function determineLoanStatus(
  currentBorrowAmount: bigint,
  healthFactor: bigint,
  availableToBorrow: bigint,
  isAuctioned: boolean
): LoanStatus {
  if (currentBorrowAmount !== BigInt(0) && healthFactor < BigInt(1e4)) {
    if (isAuctioned) {
      return LoanStatus.TO_REDEEM;
    } else {
      return LoanStatus.TO_REPAY;
    }
  } else if (availableToBorrow > BigInt(0)) {
    return LoanStatus.TO_BORROW;
  } else {
    return LoanStatus.ONLY_REPAY;
  }
}
