import { RAY, SECONDS_PER_YEAR } from "../constants";
import { uTokenFactoryContract } from "../contracts";
import { ReserveData } from "../contracts/UTokenFactoryContract";
import { CurrencyApyData } from "components/providers/ApyProvider";
import { IErc20Currency } from "./types/currency/ICurrency";

class ApyModule {
  private reserveDatas: {
    currencyName: string;
    data: ReserveData;
    timestamp: number;
  }[] = [];

  private async getReserveData(currency: IErc20Currency) {
    const index = this.reserveDatas.findIndex(
      (data) => data.currencyName === currency.name
    );

    if (index !== -1) {
      return this.reserveDatas[index].data;
    } else {
      const data = await uTokenFactoryContract.getReserveData(currency.address);

      this.reserveDatas = [
        ...this.reserveDatas,
        { currencyName: currency.name, data, timestamp: Date.now() },
      ];

      return data;
    }
  }

  async getApys(currency: IErc20Currency): Promise<CurrencyApyData> {
    const borrowApy = await this.getBorrowApy(currency);
    const depositApy = await this.getDepositApy(currency);

    return { currencyName: currency.name, borrowApy, depositApy };
  }

  async getDepositApy(currency: IErc20Currency): Promise<number> {
    const currentLiquidityRate = (await this.getReserveData(currency))
      .currentLiquidityRate;

    const aprNumber = Number(currentLiquidityRate) / Number(RAY);

    return (
      (aprNumber / parseInt(SECONDS_PER_YEAR) + 1) **
        parseInt(SECONDS_PER_YEAR) -
      1
    );
  }

  async getBorrowApy(currency: IErc20Currency): Promise<number> {
    const currentVariableBorrowRate = (await this.getReserveData(currency))
      .currentVariableBorrowRate;
    const apr = Number(currentVariableBorrowRate) / Number(RAY);

    const base = apr / parseInt(SECONDS_PER_YEAR) + 1;
    const apy = base ** parseInt(SECONDS_PER_YEAR) - 1;

    return apy;
  }
}

export default new ApyModule();
