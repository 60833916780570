import { Address, erc20Abi } from "viem";
import { externalWalletModule } from "../clients/verisModule";
import {
  Hash,
  OptionsWriteMethod,
  Output,
} from "@unlockdfinance/verislabs-web3";
import AbstractContract from "./AbstractContract";
import { WalletType } from "./types";
import executeContractMethod from "logic/helpers/executeContractMethod";

export default class Erc20Contract extends AbstractContract<typeof erc20Abi> {
  constructor(address: Address) {
    super({ address }, erc20Abi);
  }

  async balanceOf(address: Address) {
    return (await this.viemReadContract).read.balanceOf([address]);
  }

  async allowance(owner: Address, spender: Address) {
    return (await this.viemReadContract).read.allowance([owner, spender]);
  }

  async approve(
    spender: Address,
    amount: bigint,
    options?: OptionsWriteMethod
  ): Promise<Output<void>> {
    return externalWalletModule.handleViemTransaction(
      async () =>
        // @ts-ignore
        (await this.viemWriteContract).write.approve([spender, amount]),
      options
    );
  }

  transfer(
    from: WalletType,
    to: Address,
    amount: bigint,
    options?: OptionsWriteMethod
  ): Promise<Output<void>> {
    return executeContractMethod(this, "transfer", [to, amount], options, from);
  }
}
