import ActionContract from "./ActionContract";
import BulkTransferContract from "./BulkTransferContract";
import CryptopunksContract from "./CryptopunksContract";
import DelegationWalletFactoryContract from "./DelegationWalletFactoryContract";
import { DelegationWalletRegistryContract } from "./DelegationWalletRegistryContract";
import { ERC721Contract } from "./ERC721Contract";
import { LockeysManager } from "./LockeysManager";
import UTokenFactoryContract from "./UTokenFactoryContract";
import WethContract from "./WethContract";
import SellNowContract from "./SellNowContract";
import MarketContract from "./MarketContract";
import UnlockdContract from "./UnlockdContract";
import UnlockdHelperContract from "./UnlockdHelperContract";
import AuctionContract from "./AuctionContract";
import MarketEventsContract from "./MarketEventsContract";
import AuctionEventsContract from "./AuctionEventsContract";
import { Address } from "viem";
import EarnWethGatewayContract from "./EarnWethGatewayContract";
import { AddressProvider } from "@unlockdfinance/verislabs-web3";
import { app } from "app.config";

const unlockdContract = new UnlockdContract(app.CONTRACT_ADDRESSES.unlockd);

const cryptopunksContract = new CryptopunksContract(
  app.PUNKS_COLLECTION.address
);

const earnWethGateway = new EarnWethGatewayContract(
  app.CONTRACT_ADDRESSES.earnWethGateway
);

const lockeysContract = new ERC721Contract(
  app.CONTRACT_ADDRESSES.theLockeysMainnet
);

const lockeysManagerContract = new LockeysManager(
  app.CONTRACT_ADDRESSES.lockeyManager
);

const delegationWalletFactoryContract = new DelegationWalletFactoryContract(
  app.CONTRACT_ADDRESSES.walletFactory
);

const delegationWalletRegistryContract = new DelegationWalletRegistryContract(
  app.CONTRACT_ADDRESSES.walletRegistry
);

const bulkTransferContract = new BulkTransferContract(
  app.CONTRACT_ADDRESSES.bulkTransfer
);

const actionContract = new ActionContract(app.UNLOCKD_MODULES.ACTION);

const uTokenFactoryContract = new UTokenFactoryContract(
  app.CONTRACT_ADDRESSES.uTokenFactory
);

const wethContract = new WethContract(app.CONTRACT_ADDRESSES.weth);

const sellNowContract = new SellNowContract(app.UNLOCKD_MODULES.SELLNOW);

const marketContract = new MarketContract(app.UNLOCKD_MODULES.MARKET);

const marketEventsContract = new MarketEventsContract(
  app.CONTRACT_ADDRESSES.unlockd
);

const unlockdHelperContract = new UnlockdHelperContract(
  app.CONTRACT_ADDRESSES.unlockdHelper
);

const auctionContract = new AuctionContract(app.UNLOCKD_MODULES.AUCTION);

const auctionEventsContract = new AuctionEventsContract(
  app.CONTRACT_ADDRESSES.unlockd
);

AddressProvider.init(
  new (class extends AddressProvider {
    lookupAddress(id: string): Promise<Address> {
      return unlockdContract.moduleIdToProxy(BigInt(id));
    }
  })(app.CONTRACT_ADDRESSES)
);

export {
  cryptopunksContract,
  earnWethGateway,
  lockeysContract,
  lockeysManagerContract,
  delegationWalletFactoryContract,
  delegationWalletRegistryContract,
  bulkTransferContract,
  actionContract,
  uTokenFactoryContract,
  wethContract,
  sellNowContract,
  marketContract,
  marketEventsContract,
  unlockdContract,
  unlockdHelperContract,
  auctionContract,
  auctionEventsContract,
};
