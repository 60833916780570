import { Address } from "viem";
import { chainlinkPriceFeedAbi } from "./abis/chainlinkPriceFeedAbi";
import AbstractContract from "./AbstractContract";

export default class ChainlinkPriceFeed extends AbstractContract<
  typeof chainlinkPriceFeedAbi
> {
  constructor(address: Address) {
    super({ address }, chainlinkPriceFeedAbi);
  }

  async latestAnswer(): Promise<bigint> {
    return (await this.viemReadContract).read.latestAnswer();
  }
}
