import { Address, WatchContractEventReturnType } from "viem";
import { marketAbi } from "./abis/marketAbi";
import AbstractContract from "./AbstractContract";

export type BidArgs = {
  loanId?: `0x${string}` | `0x${string}`[] | null | undefined;
  orderId?: `0x${string}` | `0x${string}`[] | null | undefined;
  assetId?: `0x${string}` | `0x${string}`[] | null | undefined;
};

export type CancelArgs = {
  loanId?: `0x${string}` | `0x${string}`[] | null | undefined;
  orderId?: `0x${string}` | `0x${string}`[] | null | undefined;
};

export type BidTopics = {
  loanId?: `0x${string}`;
  orderId?: `0x${string}`;
  assetId?: `0x${string}`;
  amount?: bigint;
  user?: `0x${string}`;
  isMarketItem: boolean;
};

export type CancelTopics = {
  loanId?: `0x${string}`;
  orderId?: `0x${string}`;
  owner?: `0x${string}`;
};

export type BoughtTopics = {
  loanId?: `0x${string}`;
  orderId?: `0x${string}`;
  assetId?: `0x${string}`;
  amount?: bigint;
  user?: `0x${string}`;
};

export default class MarketEventsContract extends AbstractContract<
  typeof marketAbi
> {
  constructor(address: Address) {
    super({ address }, marketAbi);
  }

  async onOrderCancelled(
    args: CancelArgs,
    onLogs: (logs: CancelTopics) => void
  ): Promise<WatchContractEventReturnType> {
    return (await this.viemReadContract).watchEvent.MarketCancelAuction(args, {
      onLogs: (logs) => onLogs(logs[0].args),
    });
  }

  async onBought(
    args: BidArgs,
    onLogs: (logs: BoughtTopics) => void
  ): Promise<WatchContractEventReturnType> {
    return (await this.viemReadContract).watchEvent.MarketBuyNow(args, {
      onLogs: (logs) => onLogs(logs[0].args),
    });
  }

  async onBid(
    args: BidArgs,
    onLogs: (logs: BidTopics) => void
  ): Promise<WatchContractEventReturnType> {
    return (await this.viemReadContract).watchEvent.MarketBid(args, {
      onLogs: (logs) => onLogs({ ...logs[0].args, isMarketItem: true }),
    });
  }
}
