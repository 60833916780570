import { Address } from "viem";
import { lockeysManagerAbi } from "./abis/lockeysManagerAbi";
import AbstractContract from "./AbstractContract";

export class LockeysManager extends AbstractContract<typeof lockeysManagerAbi> {
  constructor(address: Address) {
    super({ address }, lockeysManagerAbi);
  }

  async getLockeyDiscountPercentage(): Promise<bigint> {
    return (await this.viemReadContract).read.getLockeyDiscountPercentage();
  }

  async getLockeyDiscountPercentageOnDebtMarket(): Promise<bigint> {
    return (
      await this.viemReadContract
    ).read.getLockeyDiscountPercentageOnDebtMarket();
  }
}
