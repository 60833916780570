import { Address } from "viem";
import { unlockdHelperAbi } from "./abis/unlockdHelperAbi";
import AbstractContract from "./AbstractContract";

export default class UnlockdHelperContract extends AbstractContract<
  typeof unlockdHelperAbi
> {
  constructor(address: Address) {
    super({ address }, unlockdHelperAbi);
  }

  async getAssetId(collection: Address, tokenId: bigint) {
    return (await this.viemReadContract).read.getAssetId([collection, tokenId]);
  }
}
