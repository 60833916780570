"use client";
import { verisModule } from "clients/verisModule";
import { useModalConnectWallets } from "components/hooks/useModalConnectWallets";
import { useModalSign } from "components/hooks/useModalSign";
import { useModalSwitchNetwork } from "components/hooks/useModalSwitchNetwork";
import { useCustomWallet } from "components/providers";
import { useUnlockdSession } from "components/providers/UnlockdSessionProvider";
import { useState } from "react";
import { useUpdateEffect } from "react-use";

export const useActionProtected = (action: () => void, isDisabledLogin?: boolean): (() => void) => {
  const { isConnected, chainId } = useCustomWallet();
  const { isLoggedIn } = useUnlockdSession();
  const [sensor, toggle] = useState<boolean>(false);
  const [isProcessInitiated, setProcessInitiated] = useState<boolean>(false);
  const [, toggleModalConnectWallet] = useModalConnectWallets();
  const [, toggleModalSwitchNetwork] = useModalSwitchNetwork();
  const [, toggleModalSign] = useModalSign();

  const launcher = () => {
    toggle(!sensor);
    setProcessInitiated(true);
  };

  useUpdateEffect(() => {
    if (isProcessInitiated) {
      if (!isConnected) {
        toggleModalConnectWallet();
      } else if (chainId && chainId !== verisModule.wagmiConfig.chains[0].id) {
        toggleModalSwitchNetwork();
      } else if (!isLoggedIn && !isDisabledLogin) {
        toggleModalSign();
      } else {
        setProcessInitiated(false);
        action();
      }
    }
  }, [sensor, isConnected, chainId, isLoggedIn]);

  return launcher;
};
