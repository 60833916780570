import {
  Address,
  OptionsWriteMethod,
  Output,
} from "@unlockdfinance/verislabs-web3";
import formatCurrencyValue from "utils/formatCurrencyValue";
import {
  CurrencyData,
  ICurrency,
  IErc20Currency,
  INativeCurrency,
} from "./ICurrency";
import {
  parseFromDollar,
  parseToDollar,
} from "@unlockdfinance/verislabs-web3/utils";
import ChainlinkPriceFeed from "contracts/ChainlinkPriceFeed";
import apyModule from "logic/ApyModule";
import { externalWalletModule, verisModule } from "clients/verisModule";
import { getBalance, sendTransaction } from "@wagmi/core";
import { WalletType } from "contracts/types";
import Erc20Contract from "contracts/Erc20Contract";
import { isSmartWallet } from "contracts/helpers";
import unlockdWalletModule from "logic/UnlockdWalletModule";

export abstract class Currency implements ICurrency {
  decimals: number;
  decimalsToShow: number;
  srcPrimary: string;
  srcSecondary: string;
  label: string;
  name: string;
  type: "erc20" | "native";
  private chainlinkAddress: Address;
  private _chainlinkContract?: ChainlinkPriceFeed;
  private dollarRate?: { amount: bigint; expirationTime: number };

  constructor({
    decimals,
    decimalsToShow,
    label,
    name,
    srcPrimary,
    srcSecondary,
    type,
    chainlinkAddress,
  }: CurrencyData) {
    this.decimals = decimals;
    this.decimalsToShow = decimalsToShow;
    this.label = label;
    this.srcPrimary = srcPrimary;
    this.srcSecondary = srcSecondary;
    this.name = name;
    this.type = type;
    this.chainlinkAddress = chainlinkAddress;
  }

  get isNative(): boolean {
    return this.type === "native";
  }

  get isErc20(): boolean {
    return this.type === "erc20";
  }

  get isWeth(): boolean {
    return this.name === "weth";
  }

  private chainlinkContract(): ChainlinkPriceFeed {
    if (!this._chainlinkContract) {
      this._chainlinkContract = new ChainlinkPriceFeed(this.chainlinkAddress);
    }

    return this._chainlinkContract;
  }

  async getDollarRate(): Promise<bigint> {
    if (
      !this.dollarRate ||
      Date.now() - this.dollarRate.expirationTime < Date.now()
    ) {
      const dollarRate = await this.chainlinkContract().latestAnswer();

      this.dollarRate = {
        amount: dollarRate,
        expirationTime: Date.now() + 1000 * 60 * 5,
      };
    }

    return this.dollarRate.amount;
  }

  async parseToDollar(amount: bigint): Promise<bigint> {
    const dollarRate = await this.getDollarRate();

    return parseToDollar(amount, dollarRate, this.decimals);
  }

  async parseFromDollar(dollars: bigint): Promise<bigint> {
    const dollarRate = await this.getDollarRate();

    return parseFromDollar(dollars, dollarRate, this.decimals);
  }

  formatAmount(
    value: bigint,
    decimals?: number,
    decimalsToShow?: number
  ): string {
    return formatCurrencyValue(value, this, decimals, decimalsToShow);
  }

  abstract getWalletBalance(address: Address): Promise<bigint>;

  abstract transfer(
    from: WalletType,
    to: Address,
    amount: bigint
  ): Promise<Output<void>>;
}

export class Erc20Currency extends Currency implements IErc20Currency {
  address: Address;
  type: "erc20" = "erc20";
  contract: Erc20Contract;

  constructor({
    address,
    decimals,
    decimalsToShow,
    label,
    name,
    srcPrimary,
    srcSecondary,
    chainlinkAddress,
  }: {
    address: Address;
    decimals: number;
    decimalsToShow: number;
    name: string;
    srcPrimary: string;
    srcSecondary: string;
    label: string;
    chainlinkAddress: Address;
  }) {
    super({
      address,
      decimals,
      decimalsToShow,
      label,
      name,
      srcPrimary,
      srcSecondary,
      type: "erc20",
      chainlinkAddress,
    });

    this.address = address;
    this.contract = new Erc20Contract(address);
  }

  async getBorrowApy(): Promise<number> {
    return apyModule.getBorrowApy(this);
  }

  async getEarnApy(): Promise<number> {
    return apyModule.getDepositApy(this);
  }

  getWalletBalance(address: Address) {
    return this.contract.balanceOf(address);
  }

  transfer(
    from: WalletType,
    to: Address,
    amount: bigint,
    options?: OptionsWriteMethod
  ) {
    return this.contract.transfer(from, to, amount, options);
  }
}

export class NativeCurrency extends Currency implements INativeCurrency {
  type: "native" = "native";

  constructor({
    decimals,
    decimalsToShow,
    label,
    name,
    srcPrimary,
    srcSecondary,
    chainlinkAddress,
  }: {
    decimals: number;
    decimalsToShow: number;
    name: string;
    srcPrimary: string;
    srcSecondary: string;
    label: string;
    chainlinkAddress: Address;
  }) {
    super({
      decimals,
      decimalsToShow,
      label,
      name,
      srcPrimary,
      srcSecondary,
      type: "native",
      chainlinkAddress,
    });
  }

  async getWalletBalance(address: Address) {
    const balance = await getBalance(verisModule.wagmiConfig, { address });

    return balance.value;
  }

  async transfer(
    from: WalletType,
    to: Address,
    amount: bigint,
    options?: OptionsWriteMethod
  ): Promise<Output<void>> {
    if (isSmartWallet(from)) {
      return unlockdWalletModule.sendTransaction(to, amount, options);
    } else {
      return externalWalletModule.sendTransaction(to, amount, options);
    }
  }
}
