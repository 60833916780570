import useAlertNotification from "components/hooks/useAlertNotification";
import { useCustomWallet } from "components/providers";
import { handleErrors } from "errors";
import { lifeCycleLiterals } from "literals";
import { useErrorAlert } from "logic/hooks";
import { deployUnlockdWallet } from "logic/wallet";
import { useState } from "react";
import track from "logic/track";

export const useDeployUnlockdWallet = (): {
  isWalletCreated: boolean;
  handleCreateWallet: () => void;
} => {
  const [error, setError] = useState<Error | null>(null);
  useErrorAlert(error);
  const { hasSmartAddress, address, onUnlockdWalletCreated } =
    useCustomWallet();
  const [_, openAlertNotification, closeAlertNotification] =
    useAlertNotification();

  const handleCreateWallet = async () => {
    try {
      if (address) {
        const safeAddress = await deployUnlockdWallet(address!, {
          onSignaturePending: () => {
            openAlertNotification(
              "loading",
              lifeCycleLiterals.createWallet.onSignPending,
              5000000
            );
          },
          onLoading: () => {
            openAlertNotification(
              "info",
              lifeCycleLiterals.createWallet.onTxPending,
              5000000
            );
          },
        });

        track.event("account", "account_create_success")

        openAlertNotification(
          "success",
          lifeCycleLiterals.createWallet.onSuccess,
          5000
        );

        onUnlockdWalletCreated?.(safeAddress);
      }
    } catch (err) {
      track.event("account", "account_create_fail")

      closeAlertNotification();
      setError(handleErrors(err, "createWallet"));
    }
  };

  return { isWalletCreated: !!hasSmartAddress, handleCreateWallet };
};
