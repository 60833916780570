import { Address } from "viem";
import { wEthAbi } from "./abis/wEthAbi";
import { OptionsWriteMethod, Output } from "@unlockdfinance/verislabs-web3";
import { externalWalletModule } from "../clients/verisModule";
import AbstractContract from "./AbstractContract";

export default class WethContract extends AbstractContract<typeof wEthAbi> {
  constructor(address: Address) {
    super({ address }, wEthAbi);
  }

  async allowance(from: Address, to: Address) {
    return (await this.viemReadContract).read.allowance([from, to]);
  }

  approve(
    to: Address,
    amount: bigint,
    options?: OptionsWriteMethod
  ): Promise<Output<void>> {
    return externalWalletModule.handleViemTransaction(
      // @ts-ignore
      async () => (await this.viemWriteContract).write.approve([to, amount]),
      options
    );
  }

  deposit(value: bigint, options?: OptionsWriteMethod): Promise<Output<void>> {
    return externalWalletModule.handleViemTransaction(
      async () =>
        (await this.viemWriteContract).write.deposit({
          account: externalWalletModule.address!,
          value,
          chain: undefined,
        }),
      options
    );
  }

  withdraw(
    amount: bigint,
    options?: OptionsWriteMethod
  ): Promise<Output<void>> {
    return externalWalletModule.handleViemTransaction(
      async () =>
        // @ts-ignore
        (await this.viemWriteContract).write.withdraw([amount]),
      options
    );
  }
}
