import { Address } from "viem";
import { unlockdAbi } from "./abis/unlockdAbi";
import AbstractContract from "./AbstractContract";

export default class UnlockdContract extends AbstractContract<
  typeof unlockdAbi
> {
  constructor(address: Address) {
    super({ address }, unlockdAbi);
  }

  async moduleIdToProxy(moduleId: bigint): Promise<Address> {
    return (await this.viemReadContract).read.moduleIdToProxy([moduleId]);
  }
}
